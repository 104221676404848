import React, { useState } from "react";
import "./UserModal.css";
import countriesList from "../../../common/Inputs/countries.json";

const UserModal = ({
  name,
  email,
  phoneNumber,
  status,
  balance,
  isCreate,
  isUpdate,
  currentMonthZPoints,
  lastMonthZPoints,
  cognitoId,
  joiningDate,
  sendInvoice,
  setCurrentMonthZPointsState,
  setLastMonthZPointsState,
  setNameState,
  setEmailState,
  setPhoneNumberState,
  setStatusState,
  setBalanceState,
  isOpen,
  setIsOpen,
  handleUpdateUser,
  handleCreateUser,
}) => {
  const [errors, setErrors] = useState({});
  const [country, setCountry] = useState(
    phoneNumber?.slice(0, phoneNumber.length - 10) || 91
  );

  const formatDate = (epochDate) => {
    if (!epochDate) return "N/A";
    const date = new Date(epochDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Name is required";
    if (!email) newErrors.email = "Email is required";
    if (phoneNumber.length>10 || !phoneNumber) newErrors.phoneNumber = "Phone number is required";
    if (!status) newErrors.status = "Status is required";
    if (!balance) newErrors.balance = "Balance is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validate()) return;

    console.log("submit");
    

    if (isCreate) {
      setPhoneNumberState(`+${country}${phoneNumber}`);
      handleCreateUser();
    } else if (isUpdate) {
      console.log("up");
      setPhoneNumberState(`+${country}${phoneNumber}`);
      handleUpdateUser();
    }
    setIsOpen(false);
  };

  const handleClose = (event) => {
    if (
      event.target.className === "modal" ||
      event.target.className === "close-button"
    ) {
      setIsOpen(false);
      setErrors({});
      setNameState("");
      setEmailState("");
      setCountry(91);
      setCurrentMonthZPointsState(0);
      setLastMonthZPointsState(0);
      setPhoneNumberState("");
      setStatusState("");
      setBalanceState("");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal" onClick={handleClose}>
      <div className="modal-content bg-white !w-[600px]" onClick={(e) => e.stopPropagation()}>
        <div className="close-button" onClick={handleClose}>
          ×
        </div>
        <div>
          {/* Name and Email */}
          <div className="form-group">
            <div className="w-full">
              <label>Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setNameState(e.target.value)}
              />
              {errors.name ? (
                <span className="error">{errors.name}</span>
              ) : (
                <p className="h-[12px]"></p>
              )}
            </div>
            <div className="w-full">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmailState(e.target.value)}
              />
              {errors.email ? (
                <span className="error">{errors.email}</span>
              ) : (
                <p className="h-[12px]"></p>
              )}
            </div>
          </div>

          {/* Country Code, Phone Number */}
          <div className="form-group">
            <div className="w-full">
              <label>Country Code</label>
              <select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                {countriesList.map((country) => (
                  <option key={Math.random()} value={country.value}>
                    {country.name}
                  </option>
                ))}
              </select>
              <p className="h-[12px]"></p>
            </div>
            <div className="w-full">
              <label>Phone Number</label>
              <input
                type="number"
                value={
                  phoneNumber.length > 10 ? phoneNumber.slice(-10) : phoneNumber
                }
                onChange={(e) => setPhoneNumberState(e.target.value)}
              />
              {errors.phoneNumber ? (
                <span className="error">{errors.phoneNumber}</span>
              ) : (
                <p className="h-[12px]"></p>
              )}
            </div>
          </div>

          {/* Status and Balance */}
          <div className="form-group">
            <div className="w-full">
              <label>User Status</label>
              <select
                value={status}
                onChange={(e) => setStatusState(e.target.value)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
              {errors.status ? (
                <span className="error">{errors.status}</span>
              ) : (
                <p className="h-[12px]"></p>
              )}
            </div>
            <div className="w-full">
              <label>Balance</label>
              <input
                type="number"
                value={balance}
                onChange={(e) => setBalanceState(e.target.value)}
              />
              {errors.balance ? (
                <span className="error">{errors.balance}</span>
              ) : (
                <p className="h-[12px]"></p>
              )}
            </div>
          </div>

          {/* Attandance and Joining Date */}

          {isUpdate && (
            <div className="form-group">
              <div className="w-full">
                <label>Attendance</label>

                <div className="flex flex-row items-center justify-between w-full gap-2">
                  <input
                    type="number"
                    value={currentMonthZPoints}
                    onChange={(e) =>
                      setCurrentMonthZPointsState(e.target.value)
                    }
                  />
                  <span className="font-semibold text-lg">/</span>
                  <input
                    type="number"
                    value={lastMonthZPoints}
                    onChange={(e) => setLastMonthZPointsState(e.target.value)}
                  />
                </div>

                <p className="h-[12px]"></p>
              </div>
              <div className="w-full">
                <label>Joining Date</label>
                <input
                  type="text"
                  disabled
                  value={formatDate(Number(joiningDate))}
                />

                <p className="h-[12px]"></p>
              </div>
            </div>
          )}

          {/* Submit Button */}
          <button onClick={handleSubmit} className="submit-button">
            {isCreate ? "Create" : "Update"}
          </button>
          {isUpdate && (
            <button
              onClick={(e) => {
                setIsOpen(false);
                sendInvoice(cognitoId);
              }}
              type="button"
              className="submit-button"
            >
              Send Invoice
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserModal;
