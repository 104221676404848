import React, { useContext, useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router";
import Context from "../../Context/Context";
import WarningModal from "./WarningModal";

export default function PaypalPayment({ product }) {
  const navigate = useNavigate();
  const { userData } = useContext(Context);
  const [showModal, setShowModal] = useState(false);
  const [showCancellationAlert, setShowCancellationAlert] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      // Assuming you have an API endpoint for cancelling subscriptions
      await API.del("user", `/user/cancel-subscription/${userData.subscriptionId}`, {
        body: {
          subscriptionId: userData.subscriptionId,
          cognitoId: userData.cognitoId
        }
      });

      // Show cancellation success alert
      setShowCancellationAlert(true);
    } catch (error) {
      console.error("Subscription cancellation error:", error);
      // Optionally show an error alert
      alert("Failed to cancel subscription. Please try again.");
    }
  };

  const handleCancellationAlertClose = () => {
    setShowCancellationAlert(false);
    // Reload the page
    window.location.reload();
  };

  React.useEffect(() => {
    const initializePayPalButtons = () => {
      const container = document.getElementById(
        `paypal-button-container-${product.planId}`
      );
      if (container) {
        container.innerHTML = "";
      }

      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            label: "subscribe",
          },
          onClick: function (data, actions) {
            if (userData.subscriptionId) {
              setShowModal(true);
              return actions.reject();
            }
            return actions.resolve();
          },

          createSubscription: function (data, actions) {
            return actions.subscription.create({
              plan_id: product.planId,
              application_context: {
                shipping_preference: "NO_SHIPPING",
              },
            });
          },

          onApprove: async function (data, details) {
            try {
              // Calculate period and interval based on duration
              const periodConfig = {
                Month: { period: "monthly", interval: 1 },
                Year: { period: "yearly", interval: 1 }
              };
              
              const { period, interval } = periodConfig[product.durationText] || 
                { period: "monthly", interval: 1 };

              // Prepare data for unified payment API
              const paymentData = {
                institutionId: userData.institution,
                cognitoId: userData.cognitoId,
                subscriptionId: data.subscriptionID,
                productId: product.productId,
                amount: product.amount,
                currency: "USD", // or get from product if available
                period: period,
                interval: interval,
                subscriptionType: product.heading,
                planId: product.planId,
                emailId: userData.emailId
              };

              // Call the unified payment API
              await API.put("user", "/user/add-payment-data", {
                body: paymentData,
              });

              navigate("/paymentsuccessful");
            } catch (error) {
              console.error("Payment processing error:", error);
              navigate("/paymentfailed");
            }
          },

          onError: function (err) {
            console.error("PayPal error:", err);
            navigate("/paymentfailed");
          },
        })
        .render(`#paypal-button-container-${product.planId}`);
    };

    if (window.paypal) {
      initializePayPalButtons();
    }
  }, [product, userData, navigate]);

  return (
    <div className="w-full max-w-md mx-auto">
      <div
        id={`paypal-button-container-${product.planId}`}
        className="mb-4"
      ></div>
      <WarningModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onCancel={handleCancelSubscription}
      />

      {/* Cancellation Success Alert */}
      {showCancellationAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl text-center">
            <h2 className="text-2xl font-bold mb-4">Subscription Cancelled</h2>
            <p className="mb-4">Your subscription has been successfully cancelled.</p>
            <button 
              onClick={handleCancellationAlertClose}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}