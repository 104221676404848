import React from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";

const Terms = () => {
    const condition = [
        {
            title1: ' Terms & Condition',
            para1: ' Welcome to Happyprancer! By using our website, you agree to comply with and be bound by the following terms and conditions of use.Please read these terms carefully before using our website.',
        },
        {
            title2: 'Acknowledgement',
            para2: ' By participating in Happyprancer virtual classes, you acknowledgethat you will receive information and instruction about Happyprancer dance workouts. You understand that Happyprancer requires physical exertion, which may be strenuous and may cause physical injury. You fully acknowledge and are aware of the risks involved in participating in Happyprancer virtual classes.',
        },
        {
            title3: 'Medical Consultation',
            para3: ' You understand and acknowledge that it is your responsibility to consult with a physician prior to and regarding your participation in any physical fitness program, including Zumba. You represent and warrant that you have no medical condition that would prevent your participation in physical fitness activities.',
        },
        {
            title4: 'Assumption of Risk',
            para4: ' By participating in Happyprancer virtual classes, you assume full responsibility for any risk, injuries, or damages, known and unknown, which you might incur as a result of participating in the program.',
        },
        {
            title5: 'Waiver of Claims',
            para5: ' In further consideration of being permitted to participate in the Happyprancer virtual classes, you knowingly, voluntarily and expressly waive any claim you may have against the Instructor, the founder or any core member for injuries or damages that you may sustain as a result of participating in Happyprancer sessions.',
        },
        {
            title6: 'Identification',
            para6: ' You agree to indemnify and hold harmless Happyprancer, its officers, directors, employees, agents, and affiliates from any and all claims, damages, expenses, including reasonable attorneys’ fees, arising out of or resulting from your participation in Happyprancer virtual classes.',
        },
        {
            title7: ' Governing Law',
            para7: ' These terms and conditions shall be governed by and construed in accordance with the laws of India. Any dispute arising out of or related to these terms and conditions shall be subject to the exclusive jurisdiction of the courts located in India.',
        },
        {
            title8: ' Modification',
            para8: ' Happyprancer reserves the right to modify these terms and conditions at any time without prior notice. Your continued use of our website constitutes your agreement to be bound by such modifications.',
            para9: ' By using our website, you agree to comply with and be bound by these terms and conditions of use.',
            para10: 'For more information or if you have any queries regarding these terms and conditions, please feel free to contact us at admin@happyprancer.com. We are happy to assist you in any way we  '
        }
    ]
    return (
        <>
            <div className="flex flex-col items-center w-full pb-[5rem]">
                <NavBar />
                <h1 className="mt-10">Terms and Condition</h1>
                <div className="flex flex-col items-center w-100 h-100 mt-[2rem] p-0 overflow-x-hidden w-[90vw] max-w-[80rem]">
                    {condition.map((item, index) => (
                        <div key={index}>
                            {item.para1 && <p className="mt-8">Welcome to BWORKZ! By using our website, you agree to comply with and be bound by the following terms and conditions of use. Please read these terms carefully before using our website.</p>}
                            {item.title2 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Acknowledgement</h4>}
                            {item.para2 && <p className="mt-8">By participating in BWORKZ virtual classes, you acknowledge that you will receive information and instruction about BWORKZ dance workouts. You understand that BWORKZ requires physical exertion, which may be strenuous and may cause physical injury. You fully acknowledge and are aware of the risks involved in participating in BWORKZ virtual classes.</p>}
                            {item.title3 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Medical Consultation</h4>}
                            {item.para3 && <p className="mt-8">You understand and acknowledge that it is your responsibility to consult with a physician prior to and regarding your participation in any physical fitness program, including Zumba. You represent and warrant that you have no medical condition that would prevent your participation in physical fitness activities.</p>}
                            {item.title4 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Assumption of Risk</h4>}
                            {item.para4 && <p className="mt-2">By participating in BWORKZ virtual classes, you assume full responsibility for any risk, injuries, or damages, known and unknown, which you might incur as a result of participating in the program.</p>}
                            {item.title5 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Waiver of Claims</h4>}
                            {item.para5 && <p className="mt-2">In further consideration of being permitted to participate in the BWORKZ virtual classes, you knowingly, voluntarily, and expressly waive any claim you may have against the Instructor, the founder, or any core member for injuries or damages that you may sustain as a result of participating in BWORKZ sessions.</p>}
                            {item.title6 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Identification</h4>}
                            {item.para6 && <p className="mt-2">You agree to indemnify and hold harmless BWORKZ, its officers, directors, employees, agents, and affiliates from any and all claims, damages, expenses, including reasonable attorneys’ fees, arising out of or resulting from your participation in BWORKZ virtual classes.</p>}
                            {item.title7 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Governing Law</h4>}
                            {item.para7 && <p className="mt-2">These terms and conditions shall be governed by and construed in accordance with the laws of India. Any dispute arising out of or related to these terms and conditions shall be subject to the exclusive jurisdiction of the courts located in India.</p>}
                            {item.title8 && <h4 className="text-[1rem] max450:text-[0.8rem] text-left mt-8 font-bold w-full">Modification</h4>}
                            {item.para8 && <p className="mt-2">BWORKZ reserves the right to modify these terms and conditions at any time without prior notice. Your continued use of our website constitutes your agreement to be bound by such modifications.</p>}
                        </div>
                    ))}
                    <p className="mt-2 text-left w-full">{condition[condition.length - 1].para9}</p>
                    <p className="mt-2">{condition[condition.length - 1].para10}</p>
                </div>
            </div>
            <div className="w-full"><Footer /></div>
            </>
    );

};

export default Terms;
