import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CancellationCardRazorpay from "../Components/Subscription/CancellationCardRazorpay";
import { CancellationCardPaypal } from "../Components/Subscription/CancellationCardPaypal";
import RazorpayPayment from "../Components/Subscription/RazorpayPayment";
import PaypalPayment from "../Components/Subscription/PaypalPayment";
import Context from "../Context/Context";
import '../Components/comp/Subscription.css'
import NavBar from "../Components/NavBar";


const SubscriptionCard = ({
  productName,
  description,
  price,
  period,
  currency,
  Component,
  handleSignup,
}) => (
  <div className="bg-white w-[24rem] h-[32rem] p-10 rounded-[2rem] flex flex-col items-center gap-8 shadowSubscribe border-[#FDCF08] border-[0.1rem]">
    <p className="text-xl font-semibold">{productName}</p>
    <p className="text-gray-600 text-center">{description}</p>
    <h1 className="text-center w-full text-2xl font-bold">
      {currency === "INR" ? "₹" : "$"} {price} / {period}
    </h1>
    {Component ? (
      <Component />
    ) : (
      <button
        onClick={handleSignup}
        className="w-[15rem] bg-[#ffd040] text-white px-12 py-2 rounded-2xl hover:text-[#FDCF08] hover:border-[#FDCF08] hover:border-[0.3rem] h-[3rem] flex justify-center items-center"
      >
        Subscribe
      </button>
    )}
  </div>
);

export default function Subscription() {
  const { isAuth, productList, userData } = useContext(Context);
  const navigate = useNavigate();

  // Get current subscribed product
  const getSubscribedProduct = () => {
    if (!userData?.productId || !userData?.subscriptionId || userData?.status !== "Active") {
      return null;
    }

    const subscribedProduct = productList.find(product => product.productId === userData.productId);
    return subscribedProduct || null;
  };

  const subscribedProduct = getSubscribedProduct();

  // Get available products (excluding subscribed one)
  const availableProducts = productList.filter(product =>
    product.productId !== userData?.productId
  );

  // Helper to determine which cancellation card to show based on currency
  const getCancellationCard = (product) => {
    const planData = {
      productName: product.heading,
      description: product.provides?.join(" • "),
      price: parseInt(product.amount) / 100,
      period: product.durationText,
      currency: product.currency,
      productId: product.productId
    };

    if (product.currency === "INR") {
      return <CancellationCardRazorpay key={product.productId} plan={planData} />;
    }
    return <CancellationCardPaypal key={product.productId} plan={planData} />;
  };

  return (
    <div>
      <NavBar />
      <section className="Back text-[1.5rem] flex flex-col items-center h-[90rem] max980:h-auto justify-center gap-[5rem] pb-20">
        <div className="text-center mt-20">
          <h1>INSTRUCTOR TRAINING FEES</h1>
          <h3 className="text-[1rem]">See what are the pricing in details</h3>
        </div>

        <div className="flex flex-wrap justify-center w-[90vw] max-w-[80rem] gap-[8rem]">
          {/* Show Cancellation Card for Active Subscription */}
          {subscribedProduct && getCancellationCard(subscribedProduct)}

          {/* Available Products */}
          {availableProducts.map(product => {
            const PaymentComponent = product.currency === "INR"
              ? () => <RazorpayPayment productId={product.productId} planId={product.planId} />
              : () => <PaypalPayment product={product} />;

            return (
              <SubscriptionCard
                key={product.productId}
                productName={product.heading}
                description={product.provides?.join(" • ") || product.description}
                price={parseInt(product.amount) / 100}
                period={product.durationText}
                currency={product.currency}
                Component={isAuth ? PaymentComponent : null}
                handleSignup={() => navigate("/login")}
              />
            );
          })}
        </div>
      </section>
    </div>
  );
}