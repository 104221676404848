const data = {
rows: [
{
title: "1. What is BWORKZ?",
content: `BWORKZ is a set of Dance Fitness Choreographies based on Bollywood songs. We constantly add new ones based on the upcoming and new Bollywood songs. These dance fitness choreographies are designed to give complete and wholesome workout to improve strength, stamina, coordination, balance in addition high calorie burn. Popular rhythmic Bollywood songs are chosen for such choreographies. One class in a week is sufficient to stay fit and healthy. Two classes in a week will help you tone up. Three classes in a week to help you loose weight.`,
},
{
title: "2. How to Join ?",
content: `please use subscribe option to subscribe to monthly or yearly subscription. This will take you to PayPal for payment. Once payment is made you will be asked to authenticate your email address or phone number. Once that is done you will be able to add password.`,
},
{
title: "3. How to join online workout ?",
content: `The Zoom link is provided for each online class on the BWORKZlive.com . You need to login to access the links. You do not need any password to access the Zoom from the link.`,
},
],
};

export default data;
