import React, { useContext, useState, useEffect, useRef } from "react";
import Card from "react-bootstrap/Card";
import "../Components/comp/Instructor.css";
import { API, Storage } from "aws-amplify";
// import { IoCloseCircleOutline } from "react-icons/io5";
import Context from "../Context/Context";
import { FaEdit } from "react-icons/fa";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Home/Footer";
import Popup from "reactjs-popup";
import "./Instructor.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as htmlToImage from 'html-to-image';
import certificate from "../Utils/images/certificate2.png";
import Anupam from '../Utils/images/Instructor/anupam.jpg'
const Instructor = () => {
  const domEl = useRef(null);
  const domEl1 = useRef(null);
  const [newInstructor, setNewInstructor] = useState({
    certificateNumber: "",
    joiningDate: null,
    instructorType: "",
    userName: "",
    emailId: "",
    cognitoId: "",
    phoneNumber: ""
  });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const toggleBodyScroll = (enable) => {
    // document.body.style.overflow = enable ? 'auto' : 'hidden'; 
    console.log("heeh");
  };
  const [isLoading, setIsLoading] = useState(false);
  const [instructorList, setInstructorList] = useState([]);


  useEffect(() => {
    const fetchSchedule = async () => {
      UtilCtx.current.setLoader(true);
      try {
        const response = await API.get("user", `/any/certifiedmemberget/Bworkz`);
        // setInstructorList(response);
        const sortedList = response.sort((a, b) => {
          if (a.instructorType === "Master Instructor") return -1;
          if (b.instructorType === "Master Instructor") return 1;
          return 0;
        });
        setInstructorList(sortedList);
        console.log("heheheh", response);
        UtilCtx.current.setLoader(false);
      } catch (error) {
        console.error("Error fetching schedule:", error);
        UtilCtx.current.setLoader(false);
      }
    };

    fetchSchedule();

  }, []);

  const userData = useContext(Context).userData;
  const UtilCtx = useRef(useContext(Context).util);
  const [showInstructors, setShowInstructors] = useState(false);
  const [editingInstructor, setEditingInstructor] = useState(null);
  const [editedInstructor, setEditedInstructor] = useState({
    certificateNumber: "",
    StartingDate: new Date(),
    instructorType: "",
    userName: "",
    emailId: "",
    phoneNumber: ""
  });
  const [instructorListNew, setInstructorListNew] = useState([]);
  console.log(instructorListNew);
  let capitalizedValue;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      capitalizedValue = value;
    }
    setEditedInstructor((prevState) => ({
      ...prevState,
      [name]: capitalizedValue,
    }));
  };
  const handleInputChange1 = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      capitalizedValue = value;
    }

    setNewInstructor((prevState) => ({
      ...prevState,
      [name]: capitalizedValue,
    }));
  };


  const handleFormSubmit = async (event) => {
    if (!domEl1.current) {
      console.error('DOM element not found.');
      UtilCtx.current.setLoader(false);
      return;
    }
    console.log("sssssssss", domEl1.current);
    event.preventDefault();
    UtilCtx.current.setLoader(true);
    if (!editedInstructor.StartingDate || !editedInstructor.instructorType) {
      alert("Joining Date and Instructor Type are required.");
      UtilCtx.current.setLoader(false);
      return;
    }
    try {

      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedInstructor.emailId);
      if (!isValidEmail) {
        alert("Please enter a valid email address.");
        UtilCtx.current.setLoader(false);
        return;
      }

      await setIsVisible(true);
      // await instructorId;
      // const dataUrl = await htmlToImage.toPng(domEl.current);

      // // download image
      // const link = document.createElement('a');
      // link.download = 'html-to-img.png';
      // link.href = dataUrl;
      // link.click();
      const dataUrl = await htmlToImage.toPng(domEl1.current);


      const blob = await fetch(dataUrl).then(res => res.blob());


      const file = new File([blob], 'certificate.png', { type: blob.type });
      const timestamp = Date.now();
      const imageKey = `images/${file.name}/${timestamp}`;
      await Storage.put(imageKey, file, { contentType: file.type });
      const imageUrl = await Storage.get(imageKey);




      const cleanImageUrl = imageUrl.split("?")[0];
      console.log(editedInstructor.phoneNumber)
      await API.put(
        "user",
        `/admin/update-certificate/Bworkz`,
        {
          body: {

            certificate: cleanImageUrl,
            certificateNumber: editedInstructor.certificateNumber,
            cognitoId: editedInstructor.cognitoId,
            instructorType: editedInstructor.instructorType,
            StartingDate: editedInstructor.StartingDate,
            userName: editedInstructor.userName,
            emailId: editedInstructor.emailId,
            phoneNumber: editedInstructor.phoneNumber
          },
        }
      );
      alert("Instructor updated successfully");

      setEditedInstructor({
        certificateNumber: "",
        StartingDate: null,
        instructorType: "",
        userName: "",
        emailId: "",
      });
      setEditingInstructor(null);
      setShowInstructors(false);
      const response = await API.get("user", "/any/certifiedmemberget/Bworkz");
      setInstructorList(response);
      UtilCtx.current.setLoader(false);
    } catch (error) {
      console.error("Error updating instructor data:", error);
      alert("Error updating instructor data. Please try again.");
      UtilCtx.current.setLoader(false);
      setIsVisible(false);
    }
    UtilCtx.current.setLoader(false);
    setIsVisible(false);
  };
  const [isVisible, setIsVisible] = useState(false);
  const downloadImage = async () => {
    UtilCtx.current.setLoader(true);
    if (!newInstructor.joiningDate || !newInstructor.instructorType) {
      alert("Joining Date and Instructor Type are required.");
      UtilCtx.current.setLoader(false);
      return;
    }

    if (!domEl.current) {
      console.error('DOM element not found.');
      UtilCtx.current.setLoader(false);
      return;
    }

    try {

      await setIsVisible(true);
      // await instructorId;
      // const dataUrl = await htmlToImage.toPng(domEl.current);

      // // download image
      // const link = document.createElement('a');
      // link.download = 'html-to-img.png';
      // link.href = dataUrl;
      // link.click();
      const dataUrl = await htmlToImage.toPng(domEl.current);


      const blob = await fetch(dataUrl).then(res => res.blob());

      const file = new File([blob], 'certificate.png', { type: blob.type });
      const timestamp = Date.now();
      const imageKey = `images/${file.name}/${timestamp}`;
      await Storage.put(imageKey, file, { contentType: file.type });
      const imageUrl = await Storage.get(imageKey);


      // const sortedInstructors = response.sort((a, b) => new Date(b.joiningDate) - new Date(a.joiningDate));
      // const mostRecentInstructor = sortedInstructors[0];
      const cleanImageUrl = imageUrl.split("?")[0];
      await API.post("user", `/admin/create-certificate/Bworkz`, {
        body: {
          certificate: cleanImageUrl,
          certificateNumber: newInstructor.certificateNumber,
          cognitoId: newInstructor.cognitoId,
          instructorType: newInstructor.instructorType,
          StartingDate: newInstructor.joiningDate,
          userName: newInstructor.userName,
          emailId: newInstructor.emailId,
          phoneNumber: newInstructor.phoneNumber
        }
      });
      UtilCtx.current.setLoader(false);
      alert("Certificate uploaded successfully");
    } catch (error) {
      console.error('Error converting HTML to image:', error);
      UtilCtx.current.setLoader(false);
    } finally {

      setIsVisible(false);
      setShowCreateInstructorUI(false);
      UtilCtx.current.setLoader(false);
    }


  };
  const handlejoiningDateChange = (date) => {
    const joiningDate = new Date(date);
    // const year = joiningDate.getFullYear();
    // const day = joiningDate.getDate();
    // const month = joiningDate.toLocaleString("default", { month: "long" });

    const randomDigits = Math.floor(100000 + Math.random() * 900000); // Generate 6 random digits
    const certificateNumber = `BWZ${randomDigits}`;// Generate certificate number

    setNewInstructor((prevState) => ({
      ...prevState,
      joiningDate,
      certificateNumber,
    }));
  };
  const handleJoiningDateChangeForEdit = (date) => {
    setEditedInstructor(prevState => ({
      ...prevState,
      StartingDate: date,
    }));
  };

  const getYear = (date) => {
    if (date instanceof Date) {
      return date.getFullYear();
    } else if (typeof date === 'string') {

      const parsedDate = new Date(date);
      return parsedDate.getFullYear();
    } else {

      return '';
    }
  };
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Because 11th, 12th, 13th, etc.
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const getDayAndMonth = (date) => {
    if (date instanceof Date) {
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const suffix = getOrdinalSuffix(day);
      return `${day}${suffix} ${month}`;
    } else if (typeof date === 'string') {
      const parsedDate = new Date(date);
      const day = parsedDate.getDate();
      const month = parsedDate.toLocaleString("default", { month: "long" });
      const suffix = getOrdinalSuffix(day);
      return `${day}${suffix} ${month}`;
    } else {
      return '';
    }
  };




  const handleDeleteInstructor = async (instructorId) => {

    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this instructor?"
      );

      if (!shouldDelete) {
        return;

      }
      UtilCtx.current.setLoader(true);
      await API.put("user", `/admin/update-certified-status/Bworkz`, {
        body: {
          cognitoId: editingInstructor.cognitoId,
        },
      });

      const updatedInstructorList = instructorList.filter(
        (instructor) => instructor.id !== instructorId
      );
      setInstructorListNew(updatedInstructorList);
      alert("Instructor deleted successfully");
      const response = await API.get("user", "/any/certifiedmemberget/Bworkz");
      setInstructorList(response);
      UtilCtx.current.setLoader(false);
    } catch (error) {
      console.error("Error deleting instructor:", error);
      alert("Error deleting instructor. Please try again.");
      UtilCtx.current.setLoader(false);
    }
    UtilCtx.current.setLoader(false);
  };

  const handleEditClick = (instructor) => {
    console.log(instructor)
    setEditingInstructor(instructor);
    setEditedInstructor(instructor);
    setShowInstructors(true);
    setIsPopupOpen1(true);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCancelEdit = () => {
    setEditingInstructor(null);
    setShowInstructors(false);
  };
  console.log(handleCancelEdit);

  const [filterType, setFilterType] = useState("emailId");
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchData, setsearchData] = useState({});
  console.log(searchData);
  const handleSearch = async () => {
    setIsLoading(true);
    try {
      const response = await API.get("user", "/admin/get-user-by-attribute/Bworkz", {
        queryStringParameters: {
          query: searchText,
          type: filterType,
        },
      });
      console.log("hhhhhh", response);
      // setFilteredData(response);
      // setsearchData(response[0]);
      // setNewInstructor({userName: response[0].userName,
      //   emailId: response[0].emailId,
      //   cognitoId: response[0].cognitoId,});
      const region = process.env.REACT_APP_STAGE === 'PROD' ? 'us-east-1' : 'us-east-2';

      const filteredUsers = response.filter(user => user.cognitoId.startsWith(region));


      // Use the filteredUsers if available, otherwise use the original response
      const finalResponse = filteredUsers.length > 0 ? filteredUsers : response;

      setFilteredData(finalResponse);
      setsearchData(finalResponse[0]);
      setNewInstructor({
        userName: finalResponse[0].userName,
        emailId: finalResponse[0].emailId,
        cognitoId: finalResponse[0].cognitoId,
        phoneNumber: finalResponse[0].phoneNumber
      });


      handleCreateInstructorButtonClick();
      setIsLoading(false);
    } catch (error) {
      console.error("Error searching:", error);
      alert("Error searching. Please try again.");
      setIsLoading(false);

    }
  };
  const [showSearchUI, setShowSearchUI] = useState(true);
  const [showCreateInstructorUI, setShowCreateInstructorUI] = useState(false);


  const handleSearchButtonClick = () => {
    setShowSearchUI(true);
    setShowCreateInstructorUI(false);

  };


  const handleCreateInstructorButtonClick = () => {
    setShowSearchUI(false);
    setShowCreateInstructorUI(true);

  };
  useEffect(() => {
    const handleCreateInstructor = async () => {
      // UtilCtx.current.setLoader(true);
      setIsLoading(true);
      try {
        const response = await API.get("user", "/admin/query-user-attribute/Bworkz", {
          queryStringParameters: {
            type: filterType,
          },
        });

        // const filteredData = response.filter((item) => {
        //   if (filterType === "emailId") {
        //     return item.emailId.includes(searchText);
        //   } else if (filterType === "phoneNumber") {
        //     return item.phoneNumber.includes(searchText);
        //   } else if (filterType === "userName") {
        //     return item.userName.includes(searchText);
        //   }
        //   return true; 
        // });
        const seen = new Set();
        const filteredData = response.filter((item) => {
          let key;
          if (filterType === "emailId") {
            key = item.emailId;
          } else if (filterType === "phoneNumber") {
            key = item.phoneNumber;
          } else if (filterType === "userName") {
            key = item.userName;
          }
          if (key && key.includes(searchText) && !seen.has(key)) {
            seen.add(key);
            return true;
          }
          return false;
        });

        setFilteredData(filteredData);
        // UtilCtx.current.setLoader(false);
        setIsLoading(false);
      } catch (error) {
        console.error("Error creating instructor:", error);
        alert("Error creating instructor. Please try again.");
        // UtilCtx.current.setLoader(false);
        setIsLoading(false);
      }
    };

    if (userData && (userData.userType === "admin" || userData.userType === "instructor")) {
      handleCreateInstructor();
    }
  }, [filterType, searchText, userData]);


  // useEffect(() => {
  //   if (userData && (userData.userType === "admin" || userData.userType === "instructor")) {
  //     handleCreateInstructor();
  //   }
  // }, [userData]);

  // useEffect(() => {
  //   if (userData && (userData.userType === "admin" || userData.userType === "instructor")) {
  //     handleCreateInstructor();
  //   }
  // }, [filterType, searchText, userData]);

  return (
    <div>
      <NavBar />
      <div className="flex-col items-center instructor-contanier Background w-full ">
        <a
          href="https://forms.gle/C8WFVgrQmC9kzQhb6"
          className="text-decoration-none" target="_blank" rel="noopener noreferrer"
        >
          <div className="instructor-headline">Join us as an Instructor</div>
        </a>
        {(!userData.userType || userData.userType === 'member') && (
          <div className="h-20"> </div>
        )}

        <div className="flex w-full justify-end">
          {(!showCreateForm && userData && (userData.userType === "admin" || userData.userType === 'instructor')) && (
            <Popup
              trigger={
                <button className="w-[10rem] h-[2.8rem] m-[1rem] bg-[#FDCF08] text-[#fff] rounded-[0.4rem] mr-16 max1050:mr-4" onClick={() => {
                  setShowCreateForm(true);
                  setIsPopupOpen(true);
                  toggleBodyScroll(false);
                }}>
                  Certify Member
                </button>
              }
              modal
              nested
              open={isPopupOpen}
              onClose={() => {
                setIsPopupOpen(false);
                toggleBodyScroll(true);
                handleSearchButtonClick();
              }}
            >
              {(close) => (
                <div className="popup-container" onClick={() => {
                  setShowCreateForm(false);
                  setIsPopupOpen(false);
                  toggleBodyScroll(true);
                }}>
                  <div className="popup-content bg-white rounded-lg shadow-md" onClick={(e) => e.stopPropagation()}>
                    <div className="popup-header flex justify-between items-center bg-yellow-400 rounded-t-lg p-4 ">
                      <h2 className="popup-title text-lg font-bold text-gray-800">Certify Member</h2>
                    </div>
                    <button className="close-button absolute top-0 right-0 m-4 w-8 h-8 flex items-center justify-center rounded-full bg-yellow-400 " onClick={close}>
                      &times;
                    </button>
                    <div className="popup-body p-4">
                      {showSearchUI && (
                        <form onSubmit={(e) => {
                          e.preventDefault();
                          handleSearch();
                        }}>
                          <div className="flex flex-col gap-6">
                            <div className="form-group">
                              <select
                                value={filterType}
                                onChange={(e) => setFilterType(e.target.value)}
                                className="bg-[#E3E1E1] placeholder-[#000] p-[0.8rem] rounded-[0.41rem] h-[3rem] w-[19rem] text-black "
                              >
                                <option value="emailId">Email</option>
                                <option value="phoneNumber">Phone Number</option>
                                <option value="userName">User Name</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Search Text"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-full max-w-[19rem] text-black" // Adjust width to fit container and set max-width
                                list="filteredResults"
                              />

                              <datalist id="filteredResults">
                                {filteredData.map((item, index) => (
                                  <option key={index} value={item.emailId || item.phoneNumber || item.userName} />
                                ))}
                              </datalist>

                            </div>
                          </div>
                          {isLoading && (
                            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                              <div className="loader font-bold text-black">Loading...</div>
                            </div>
                          )}
                          <div className="form-group mt-3 flex justify-center">
                            <button
                              type="submit"
                              // onClick= {handleCreateInstructorButtonClick}
                              className="bg-yellow-400 h-[3rem] w-[10rem] rounded-[0.8rem] font-bold"
                              disabled={isLoading}
                            >
                              Search
                            </button>
                          </div>
                        </form>)}
                      {showCreateInstructorUI && (
                        <form onSubmit={(e) => {
                          // handleCreateInstructor(e);
                          close();
                        }}>
                          <div className="flex flex-col gap-6">
                            <div className="flex flex-col gap-[2.4rem]">
                              <div className="form-group">
                                {/* <input
                              type="text"
                              id="name"
                              className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                              name="name"
                              placeholder="Enter Name"
                              value={newInstructor.name}
                              onChange={handleInputChange1}
                            /> */}
                                <input
                                  type="text"
                                  id="firstName"
                                  className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                                  name="userName"
                                  placeholder="Enter Name"
                                  value={newInstructor.userName}
                                  onChange={handleInputChange1}
                                />


                              </div>
                              {/* <div className="form-group"><input
          type="text"
          id="lastName"
          className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
          name="lastName"
          placeholder="Enter Last Name"
          value={newInstructor.lastName}
          onChange={handleInputChange1}
      /></div> */}

                              <div className="form-group">
                                {/* <input
                              type="text"
                              placeholder="Instructor instructorType"
                              className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                              id="instructorType"
                              name="instructorType"
                              value={newInstructor.instructorType}
                              onChange={handleInputChange1}
                            /> */}
                                <select
                                  id="instructorType"
                                  name="instructorType"
                                  value={newInstructor.instructorType}
                                  onChange={handleInputChange1}
                                  className="bg-[#E3E1E1] placeholder-[#000] px-[1rem] rounded-[0.41rem] h-[3rem] w-[19rem] text-black"
                                >
                                  <option value="">Select Instructor Type</option>
                                  <option value="Master Instructor">Master Instructor</option>
                                  <option value="Pro Instructor">Pro Instructor</option>
                                  <option value="Instructor">Instructor</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <input
                                  type="text"
                                  placeholder="Instructor Email"
                                  className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                                  id="emailId"
                                  name="emailId"
                                  value={newInstructor.emailId}
                                // onChange={handleInputChange1}
                                />
                              </div>
                            </div>
                            {isLoading && (
                              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                                <div className="loader font-bold text-black">Loading...</div>
                              </div>
                            )}
                            <div className="form-group flex flex-row gap-4">
                              {/* <div className="w-[14rem] h-[3rem]  flex items-center justify-center hover:border-[#000]  "> */}


                              {/* </div> */}
                              <DatePicker
                                selected={newInstructor.joiningDate}
                                onChange={handlejoiningDateChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText="Select Join Date"
                                className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                              />
                            </div>
                          </div>
                          <div id="domEl" ref={domEl} style={{ display: isVisible ? 'block' : 'none' }}>
                            <img src={certificate} alt="" height={380} />
                            <div className="cont">
                              {/* <h1>{newInstructor.name}</h1>
              <p>
                {newInstructor.instructorType}
              </p> */}
                              <div className="C-Name w-64 h-[28px] ml-[83px] flex justify-center items-center">
                                <h1 className="text-xl mt-2 text-white">{newInstructor.userName}</h1>
                              </div>


                              <h3 className="C-no text-white text-[8px]">{newInstructor.certificateNumber}</h3>
                              <h3 className="C-Year text-[#D3BC6D] text-[12px]">{newInstructor.joiningDate && newInstructor.joiningDate.getFullYear()}</h3>
                              <h3 className="C-Date text-white text-[8px]">  {newInstructor.joiningDate && getDayAndMonth(newInstructor.joiningDate)}</h3>
                            </div>
                          </div>
                          <div className="form-group mt-3 flex justify-center">
                            <button type="submit" className="bg-yellow-400 h-[3rem] w-[10rem] rounded-[0.8rem] font-bold"
                              disabled={isLoading} onClick={downloadImage} >
                              Certify Member
                            </button>
                          </div>
                        </form>
                      )}

                    </div>
                  </div>
                </div>
              )}
            </Popup>
          )}
        </div>

        {/* <div className="flex w-full justify-end">
        {!showCreateForm && userData && userData.userType === 'admin' && (
            <button
              className="w-[10rem] h-[2.8rem] m-[1rem] bg-[#FDCF08] text-[#fff] rounded-[0.4rem]"
              onClick={() => setShowCreateForm(!showCreateForm)}
            >
              Create Instructor
            </button>
          )}
        </div> */}

        {showInstructors && editingInstructor && (

          <Popup
            modal
            nested
            open={isPopupOpen1}
            onClose={() => setShowInstructors(false)}
          >
            {(close) => (
              <div className="popup-container">
                <div className="popup-content bg-white rounded-lg shadow-md">
                  <div className="popup-header flex justify-between items-center bg-yellow-400 rounded-t-lg p-4 ">
                    <h2 className="popup-title text-lg font-bold text-gray-800">
                      Edit Instructor
                    </h2>
                  </div>
                  <button
                    className="close-button absolute top-0 right-0 m-4 w-8 h-8 flex items-center justify-center  bg-yellow-400"
                    onClick={() => {
                      setShowInstructors(false);
                      close();
                    }}
                  >
                    &times;
                  </button>
                  <div className="popup-body p-4">
                    <form onSubmit={(e) => {
                      // handleFormSubmit(e);
                      close();
                    }}>
                      <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-[2.4rem]">
                          <div className="form-group">
                            <input
                              type="text"
                              id="name"
                              name="userName"
                              className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                              placeholder="Name"
                              value={editedInstructor.userName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="form-group">
                            {/* <input
                      type="text"
                      placeholder="Instructor instructorType"
                      id="instructorType"
                      className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                      name="instructorType"
                      value={editedInstructor.instructorType}
                      onChange={handleInputChange}
                    /> */}
                            <select
                              id="instructorType"
                              name="instructorType"
                              value={editedInstructor.instructorType}
                              onChange={handleInputChange}
                              className="bg-[#E3E1E1] placeholder-[#000] px-[1rem] rounded-[0.41rem] h-[3rem] w-[19rem] text-black"
                            >
                              <option value="">Select Instructor Type</option>
                              <option value="Master Instructor">Master Instructor</option>
                              <option value="Pro Instructor">Pro Instructor</option>
                              <option value="Instructor">Instructor</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder="Instructor emailId"
                              id="emailId"
                              name="emailId"
                              className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                              value={editedInstructor.emailId}
                            // onChange={handleInputChange}
                            />
                          </div>
                        </div>

                        <div className="form-group flex flex-row gap-4">
                          {/* <div className="w-[14rem] h-[3rem]  flex items-center justify-center hover:border-[#000]  "> */}


                          {/* </div> */}
                          <DatePicker
                            selected={editedInstructor.StartingDate}
                            onChange={handleJoiningDateChangeForEdit}
                            dateFormat="yyyy-MM-dd"
                            placeholderText="Select Join Date"
                            className="bg-[#E3E1E1] placeholder-[#000] p-[1rem] rounded-[0.41rem] h-[2.8rem] w-[19rem] text-black"
                          />
                        </div>

                      </div>
                      <div id="domEl1" ref={domEl1} style={{ display: isVisible ? 'block' : 'none' }}>
                        <img src={certificate} alt="" height={380} />
                        <div className="cont">
                          {/* <h1>{newInstructor.name}</h1>
              <p>
                {newInstructor.instructorType}
              </p> */}
                          <div className="C-Name1 w-60 h-[28px] flex justify-center items-center ml-[120px]">
                            <h1 className="text-xl mt-2 text-white">{editedInstructor.userName}</h1>
                          </div>


                          <h3 className="C-no text-white text-[8px]">{editedInstructor.certificateNumber}</h3>
                          <h3 className="C-Year text-[#D3BC6D] text-[12px]">{getYear(editedInstructor.StartingDate)}</h3>
                          <h3 className="C-Date text-white text-[8px]">   {getDayAndMonth(editedInstructor.StartingDate)}</h3>
                        </div>
                      </div>
                      <div className="form-group gap-5 mt-3 flex justify-center" onClick={() => {
                        setIsPopupOpen1(false);
                      }}>
                        <button className="bg-yellow-400 h-[2.5rem] w-[8rem] rounded-[0.8rem] font-bold text-[14px]"
                          disabled={isLoading}
                          onClick={() => {
                            handleDeleteInstructor(editingInstructor.cognitoId);
                            setShowInstructors(false);
                          }}>Delete Instructor</button>
                        <button type="submit" className="bg-yellow-400 h-[2.5rem] w-[8rem] rounded-[0.8rem] font-bold text-[14px]"
                          disabled={isLoading}
                          onClick={handleFormSubmit}
                        // onClick={() => {
                        //   handleFormSubmit();
                        //   // setIsPopupOpen1(false);
                        // }}
                        >Update Instructor</button> </div>

                    </form>
                  </div>
                </div>
              </div>
            )}
          </Popup>

        )}

        <div
          className={`grid grid-cols-1 gap-6 md:grid-cols-3 justify-center bg `}
        >
          <div className="inst-card">
            <Card
              className="Box"
              style={{
                backgroundImage: `url(${Anupam})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '29rem',
                borderRadius: '10px'
              }}
            >
              <div className="overlay"></div>
              <div className="instructor-card-text flex flex-col items-center">
                <h4 className="text-[1.3rem] font-semibold">Anupam</h4>
                <h6>Master Instructor</h6>
              </div>
            </Card>
          </div>
          {instructorList.map((instructor, i) => {
            return (
              <div className={`inst-card`} key={i}>
                {/* {userData && userData.userType === "admin" && (
                  <IoCloseCircleOutline
                    size={32}
                    color="#FFD700"
                    onClick={() =>
                      handleDeleteInstructor(instructor.instructorId)
                    }
                  />
                )} */}
                <Card
                  className={`Box`}
                  style={{
                    backgroundImage: `url(${instructor.imgUrl})`,
                    backgroundSize: "cover",
                    backgroundinstructorType: "center",
                    height: "29rem",
                    borderRadius: "2px",
                  }}
                >
                  <div className={`overlay`}></div>
                  <div
                    className={`instructor-card-text flex flex-col items-center`}
                  >
                    <h4 className={`text-[1.3rem] font-semibold`}>
                      {/* {instructor.userName} */}
                      {(() => {
                        const words = instructor.userName.split(' ');
                        const firstWord = words[0];
                        return firstWord.length <= 2 ? instructor.userName : firstWord;
                      })()}
                    </h4>
                    <h6>{instructor.instructorType}</h6>

                    {userData && (userData.userType === "admin" || userData.userType === 'instructor') && (
                      <FaEdit
                        className="h-10"
                        onClick={() => handleEditClick(instructor)}
                      />
                    )}
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Instructor;
