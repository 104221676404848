import React, { useContext, useState } from 'react';
import { BsPeopleFill } from 'react-icons/bs';
import Context from '../../Context/Context';
import { useNavigate } from 'react-router-dom';
import youtube from '../../Utils/Png/youtube.png';
import previous from '../../Utils/Png/previous.png';
import upcoming from '../../Utils/Png/upcoming.png';

const LeftBanner = ({ attendance, due, displayAfterClick }) => {
  const [click, setClick] = useState(0);
  const UserCtx = useContext(Context);

  const Navigate = useNavigate();
  const isMember = UserCtx.userData.userType === 'member';
  const defaultImg =
    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyuNFyw05KSucqjifL3PhDFrZLQh7QAS-DTw&usqp=CAU80-804949_profile-icon-for-the-politics-category-circle-hd.png';

  return (
    <div className="w-64 bg-black max1050:bg-transparent rounded-[21px] flex flex-col overflow-auto max1050:h-[6.5rem] max1050:w-screen max1050:fixed max1050:rounded-b-none max1050:bottom-[0] max1050:left-0 max1050:items-center max1050:z-30 font-sans">
     
      <div className="flex flex-col items-center p-6 pt-12 text-white max1050:hidden">
        <img
          alt="profile"
          key={'profileUpdate2' + Date.now()}
          src={UserCtx.userData.imgUrl ? UserCtx.userData.imgUrl : defaultImg}
          className="h-24 w-24 rounded-full"
        />
        <p className="pt-3 font-bold">{UserCtx.userData.userName}</p>
        {isMember && (
          <>
            <p className="pt-1">
              Attendance:{' '}
              <span style={{ color: 'green' }}>
              {UserCtx.userData.currentMonthZPoints ? UserCtx.userData.currentMonthZPoints : 0}
              </span>{' '}
              /{' '}
              <span style={{ color: 'red' }}>
              {UserCtx.userData.lastMonthZPoints ? UserCtx.userData.lastMonthZPoints : 0}
              </span>
            </p>
            <p className="pt-1">{`Due: ${due || 0}`}</p>
          </>
        )}
        <button
          className="px-3 py-1 mt-2 text-black bg-gray-600 rounded-xl"
          onClick={() => {
            setClick(3);
            displayAfterClick(3);
          }}
        >
          Profile
        </button>
      </div>

      {/* Mobile Profile Button */}
    

      {/* Remaining Content */}
      <div className="bg-[#d9d9d944] max1050:bg-[#000] w-[100%] h-[100%] rounded-r-[7rem] rounded-b-none flex flex-col items-center justify-between py-12 max1050:p-0 max1050:max-w-[20rem] max1050:rounded-[6rem] max536:w-[90vw]">
        <ul className="gap-3 text-center flex pl-0 flex-col items-center max1050:flex-row max1050:justify-between max1050:px-2 max1050:w-[104%]">
          <li
            className={`flex flex-col gap-1 py-[0.3rem] items-center text-[1.1rem] w-[98%] ${
              click === 0 &&
              'p-2 bg-[#FDCF08] max1050:bg-[#5c5c5c] max1050:rounded-[20%]'
            } my-3 p-1 font-bold text-white rounded-md cursor-pointer max1050:w-[25%]`}
            onClick={() => {
              setClick(0);
              displayAfterClick(0);
            }}
          >
            <img
              src={upcoming}
              alt=""
              style={{
                width: '1.9rem',
                minWidth: '1.9rem',
                filter: 'invert(1)',
              }}
              className="max1050:mr-0"
            />
            <p className="max1050:text-[9.5px] max1050:font-[400] mb-0">
              Upcoming Classes
            </p>
          </li>
          <li
            className={`flex flex-col gap-1 items-center text-[1.1rem] w-[86%] p-2 font-bold text-white rounded-md ${
              click === 1 &&
              'bg-[#FDCF08] max1050:bg-[#5c5c5c] max1050:rounded-[20%]'
            } cursor-pointer max1050:w-[25%]`}
            onClick={() => {
              setClick(1);
              displayAfterClick(1);
            }}
          >
            <img
              src={previous}
              alt=""
              style={{
                width: '1.9rem',
                minWidth: '1.9rem',
                filter: 'invert(1)',
              }}
              className="max1050:mr-0"
            />
            <p className="max1050:text-[9.5px] max1050:font-[400] mb-0">
              Previous Classes
            </p>
          </li>
          <li
            className={`flex flex-col gap-1 items-center text-[1.1rem] w-[86%] p-2 font-bold text-white rounded-md ${
              click === 2 &&
              'bg-[#FDCF08] max1050:bg-[#5c5c5c] max1050:rounded-[20%]'
            } cursor-pointer max1050:w-[25%]`}
            onClick={() => {
              setClick(2);
              displayAfterClick(2);
            }}
          >
            <img
              src={youtube}
              alt=""
              style={{
                width: '1.9rem',
                minWidth: '1.9rem',
                filter: 'invert(1)',
              }}
              className="max1050:mr-0"
            />
            <p className="max1050:text-[9.5px] max1050:font-[400] mb-0">
              Video
            </p>
          </li>
          
          {UserCtx.userData.userType === 'admin' && (
            <li
              className={`flex flex-col gap-1 items-center text-[1.1rem] w-[86%] p-2 font-bold text-white rounded-md ${
                click === 4 &&
                'bg-[#FDCF08] max1050:bg-[#5c5c5c] max1050:rounded-[20%]'
              } cursor-pointer max1050:w-[25%]`}
              onClick={() => {
                setClick(4);
                displayAfterClick(4);
              }}
            >
              <BsPeopleFill
                color="white"
                size={'1.9rem'}
                className="mr-2 min-w-[1.9rem] max1050:mr-0"
              />
              <p className="max1050:text-[9.5px] max1050:font-[400] mb-0">
                Members
              </p>
            </li>
          )}
            <div className="hidden max1050:block py-2">
          <li   className={`flex flex-col gap-1 items-center text-[1.1rem] w-[86%] p-2 font-bold text-white rounded-md ${
              click === 2 &&
              'bg-[#FDCF08] max1050:bg-[#5c5c5c] max1050:rounded-[20%]'
            } cursor-pointer max1050:w-[25%]`}onClick={() => {
            setClick(3);
            displayAfterClick(3);
          }}>

            <img
              src={UserCtx.userData.imgUrl ? UserCtx.userData.imgUrl : defaultImg}
              alt=""
              style={{
                width: '1.9rem',
                minWidth: '1.9rem',
                filter: 'invert(1)',
              }}
              className="max1050:mr-0"
            />
            <p className="max1050:text-[9.5px] max1050:font-[400] mb-0">
              Profile
            </p>
          </li>
      </div>
        </ul>

        <div className="w-[80%] bg-[#ffffff79] h-20 rounded-3xl flex flex-col justify-center items-center max1050:hidden">
          <p className="font-semibold text-center mb-0">Having some Trouble?</p>
          <p
            className="font-bold cursor-pointer"
            onClick={() => {
              Navigate('/query');
            }}
          >
            contact us
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
