import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { ContextProvider } from './Context/ContextProvider'
import './index.css'
import App from './App'

console.log(process.env.REACT_APP_STAGE);

// const process = {
//   env: {
//     STAGE: 'Dev'
//   }
// }

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_STAGE === 'PROD' ? 'us-east-1' : 'us-east-2',
    userPoolId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? process.env.REACT_APP_PROD_USER_POOL_ID
        : process.env.REACT_APP_DEV_USER_POOL_ID,
    identityPoolId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? process.env.REACT_APP_PROD_IDENTITY_POOL_ID
        : process.env.REACT_APP_DEV_IDENTITY_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_STAGE === 'PROD'
        ? process.env.REACT_APP_PROD_CLIENT_ID
        : process.env.REACT_APP_DEV_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
      scope: ['openid', 'email', 'profile'],
      redirectSignIn: process.env.NODE_ENV === 'development' ?
        'http://localhost:3000/redirect' : process.env.REACT_APP_DOMAIN_PROD + '/redirect',
      redirectSignOut: process.env.NODE_ENV === 'development' ?
        'http://localhost:3000' : process.env.REACT_APP_DOMAIN_PROD,
      responseType: 'code',
    }
  },
  // Storage: [
  //   {
  //     region: "ap-south-1",
  //     bucket: "harshairborn-gymnaism-me-useraccessbucketc6094d94-jlf4r2t4q6wz",
  //     identityPoolId: "ap-south-1:68cc0fa6-065b-4bff-b41b-07751bb8609b",
  //   },
  // ],
  Storage: {
    region: "us-east-2",
    bucket: "bworkz-institution-utils",
    identityPoolId:
      process.env.REACT_APP_STAGE === "PROD"
        ? 'us-east-1:ecb64635-0a4d-4250-9cfd-3b8d034c00bb'
        : 'us-east-2:717ba2c8-7c79-431c-92a5-e1eda37109c0'
  },
  API: {
    endpoints: [
      {
        name: 'user',
        endpoint:
          process.env.REACT_APP_STAGE === 'PROD'
            ? 'https://5axni4jeib.execute-api.us-east-1.amazonaws.com/dev'
            : 'https://uoo493k43g.execute-api.us-east-2.amazonaws.com/dev',
        // : 'https://r5dp21mb28.execute-api.us-east-2.amazonaws.com/dev',
        region: process.env.REACT_APP_STAGE === 'PROD' ? 'us-east-1' : 'us-east-2'
      },
      {
        name: 'awsaiapp',
        endpoint:
          process.env.REACT_APP_STAGE === "PROD"
            ? "https://er9zh7i7md.execute-api.us-east-1.amazonaws.com/dev"
            : "https://gn41h453j1.execute-api.us-east-2.amazonaws.com/dev",
        region: process.env.REACT_APP_STAGE === "PROD" ? "us-east-1" : "us-east-2",
      }
    ]
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
)
