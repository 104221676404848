import React, { useEffect, useState } from 'react';
import { Loader2 } from 'lucide-react';
import VideoCard from '../VideoCard';
import Play from '../Play';
import SkeletonGrid from '../SkeletonGrid';

export const VideoContent = ({
  error,
  loading,
  view,
  filteredVideos,
  selectedVideo,
  handleVideoClick,
  handleClosePlayer,
  scrollableDivRef,
  selectedPlaylist,
  setVideos
}) => {
  const [localVideos, setLocalVideos] = useState([]);

  // Update local videos when filteredVideos or selectedPlaylist changes
  useEffect(() => {
    console.log('VideoContent: Updating local videos', {
      selectedPlaylist: selectedPlaylist?.videos?.length,
      filteredVideos: filteredVideos?.length
    });

    const videos = selectedPlaylist ? selectedPlaylist.videos : filteredVideos;
    setLocalVideos(videos || []);
  }, [selectedPlaylist, filteredVideos]);

  // Function to handle video updates
  const handleVideoUpdate = (videoUrl) => {
    console.log('VideoContent: Handling video update', { videoUrl });
    setLocalVideos(prevVideos => {
      const updatedVideos = prevVideos.filter(v => v.videoUrl !== videoUrl);
      console.log('VideoContent: Updated videos count:', updatedVideos.length);
      return updatedVideos;
    });
  };

  if (error) {
    return <div className="w-full text-center text-red-500 mt-8">{error}</div>;
  }

  if (view === 'grid') {
    return (
      <div
        ref={scrollableDivRef}
        className="w-full flex flex-wrap gap-4 overflow-y-auto scrollbar-hide max600:justify-center max600:px-4"
      >
        {loading && localVideos.length === 0 ? (
          <SkeletonGrid />
        ) : localVideos.length > 0 ? (
          localVideos.map((video, index) => (
            <VideoCard
              key={`${video.videoUrl}-${index}`}  // Using videoUrl as it's unique
              video={video}
              onClick={handleVideoClick}
              setVideos={setVideos}
              onVideoUpdate={handleVideoUpdate}
            />
          ))
        ) : (
          <div className="w-full text-center text-gray-500 mt-8">
            {selectedPlaylist
              ? `No videos in ${selectedPlaylist.playlistName}`
              : 'No videos found'}
          </div>
        )}

        {loading && localVideos.length > 0 && (
          <div className="w-full flex justify-center items-center py-4">
            <Loader2 className="animate-spin text-gray-500" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex-1 min-w-0">
      <Play video={selectedVideo} onClose={handleClosePlayer} />
    </div>
  );
};

export default VideoContent;