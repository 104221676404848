import React from "react";

import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";

import yellow_star from "../../Utils/Assests/yellow star.png";
import Test_1 from "../../Utils/Assests/Test-Design.png";
import Test_3 from "../../Utils/Assests/Test_3.png";

import "./Testimonial.css";
import { useState } from "react";
import testimonialsData from './Data/Testimonial'
const Testimonial = () => {


// {/*                Profile pics state                 */ }
const [testimonials, setTestimonials] = useState(testimonialsData);

const styles = ["ecllip3",];

let l = testimonials.length - 1;
while (l > 0) {
styles.push("noneSeen");
l--;
}

const leftClicked = () => {
setTestimonials((testi) => {
const tempTesti = [];
const firstTesti = testi.pop();

tempTesti.push(firstTesti);

testi.map((ts, i) => {
tempTesti.push(ts);
return ts;
});

return tempTesti;
});
};

const rightClicked = () => {
setTestimonials((testi) => {
const tempTesti = [];
const firstTesti = testi.slice(0, 1);

testi.map((ts, i) => {
if (i !== 0) {
tempTesti.push(ts);
}
return ts;
});

tempTesti.push(firstTesti[0]);

return tempTesti;
});
};

return (
<div className="RussoOne z-10">
<div className="Test-size bg-[#000000] py-[4rem] flex flex-col item-center  ">
<img className="Image_1  max980:w-[30vw] absolute z-0" src={Test_1} alt="" />
<img className="Image absolute z-0" src={Test_1} alt="" />
<h1 className=" Test-text text-[#ffff]-250 max478:text-[#ffff]-[4rem]">
TESTIMONIAL
</h1>
<div className="">
<div className="">
<ul className="feedback ">
<div className="absolute w-screen flex justify-center flex-col min-h-[25rem] ">
<img className="Design h-[17rem]  object-contain mr-8" src={Test_3} alt="" />
<img className="Design_1 h-[17rem] object-contain " src={Test_3} alt="" />
</div>
{testimonials.map((test, i) => {
return (
<li key={styles[i]}>
<img src={test.src} alt="" className={styles[i]} />
</li>
);
})}
<BsArrowLeftCircle
color="#ffff"
size={"2rem"}
className="absolute left-16 cursor-pointer max536:left-6 max500:left-2 max406:h-[1.5rem]"
onClick={leftClicked}
/>
<BsArrowRightCircle
color="#ffff"
size={"2rem"}
className="absolute right-16  cursor-pointer max536:right-6 max500:right-2 max406:h-[1.5rem]"
onClick={rightClicked}
/>
</ul>
</div>
<h1 className="mona h-[5rem] w-[100%]">{testimonials[0].name}</h1>
<div className="flex relative z-2 object-cover  justify-center max1050:pl-8 max1050:pr-8 ">
<h2 className="text-[1rem]  z-2 des text-[#ffff] w-[45rem] max800:text-[1rem] max478:text-[0.9rem] text-center font-sans"><span className="text-[1.4rem]">"</span>
{testimonials[0].description} <span className="text-[1.4rem]">"</span>
</h2>
</div>

<div className="flex  justify-center item-center pt-[1rem]">
<img
src={yellow_star}
className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] max406:h-[1rem]"
alt=""
/>
<img
src={yellow_star}
className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] "
alt=""
/>
<img
src={yellow_star}
className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] "
alt=""
/>
<img
src={yellow_star}
className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem] "
alt=""
/>
<img
src={yellow_star}
className="h-[2.6rem] mt-[0.5rem] max800:mt-[0.3rem] max800:h-[1.5rem]  "
alt=""
/>
</div>
</div>
</div>
</div>
);
};

export default Testimonial;

