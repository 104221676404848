import {useContext, useEffect} from "react";
import {API, Auth} from "aws-amplify";
import {toast} from "react-toastify";
import Context from "../../../Context/Context";
import {useNavigate} from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Redirect = () => {
  const { util, setUserData, setIsAuth } = useContext(Context);
  const InstitutionId ="Bworkz";
  const navigate = useNavigate();

  useEffect(() => {
    const redirect = async () => {
      util.setLoader(true);
      try {
        const cognito = await Auth.currentAuthenticatedUser();
        const attributes = await jwtDecode(cognito.signInUserSession.idToken.jwtToken);

        const response = await API.post(
          "user",
          `/any/user-exists/${InstitutionId}`,
          {
            body: {
              userPoolId: cognito.pool.userPoolId,
              username: attributes.email,
            }
          }
        );

        if (!response.inInstitution) {
          await API.put(
            'user',
            `/user/profile-create-or-update   `,
            {
              body: {
                userName: attributes.name,
                emailId: attributes.email,
              },
            }
          );
        }

        const userdata = await API.get(
          'user',
          `/user/profile/${InstitutionId}`,
          {}
        );
        setUserData(userdata);
        setIsAuth(true);

        toast.info('Logged in');
        util.setLoader(false);
        navigate('/dashboard');
      } catch (e) {
        toast.error('Please signup first');
        util.setLoader(false);
        console.log(e)
        navigate('/signup');
        
      }
    }

    redirect();
  }, []);
}

export default Redirect;