import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../Utils/Assests/New.png";
import facebook from "../../Utils/Assests/FB.png";
import instagram from "../../Utils/Assests/INSTA.png";
import youtube from "../../Utils/Assests/YOU.png";

const Footer = (props) => {
  const Navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.initialContent]);

  const linkGroups = {
    "Resources": [
      { name: "BWORKZ", href: "https://bworkzlive.com/" },
      { name: "Instructor Brochure", href: "https://s3-us-east-2.amazonaws.com/bworkzdocuments/brochure.pdf" },
      { name: "Instructor Application", href: "https://forms.gle/ahVeu2pSjJdWbADVA" },
      { name: "In Person BWORKZ classes", href: "https://movatiathletic.com/" },
    ],
    "Partners": [
      { name: "HappyPrancer", href: "https://happyprancer.com/" },
      { name: "Lissome", href: "http://lissome.happyprancer.com" },
      { name: "Anupamz", href: "https://www.facebook.com/share/XBcvvs7ZENoqDReS/?mibextid=ox5AEW" },
      { name: "ACE", href: "https://www.acefitness.org/" },
    ],
    "Quick Links": [
      { name: "Contact Us", onClick: () => Navigate("/query") },
      { name: "About Us", onClick: () => Navigate("/aboutUs") },
    ],
  };

  const renderLinkColumn = (title, links) => (
    <div className="flex flex-col gap-3">
      <h2 className="text-left">{title}</h2>
      <div
        className="w-full h-[0.2rem] text-white bar"
        style={{
          background: "linear-gradient(90deg, #FFD700 16.67%, #353534 90.74%)",
        }}
      ></div>
      {links.map((link, index) => (
        link.onClick ? (
          <p
            key={index}
            className="cursor-pointer text-[#fff] text-decoration-none mb-0 hover:text-[#FDCF08] transition-colors"
            onClick={link.onClick}
          >
            {link.name}
          </p>
        ) : (
          <a
            key={index}
            className="cursor-pointer text-[#fff] text-decoration-none hover:text-[#FDCF08] transition-colors"
            href={link.href}
            target="_blank"
            rel="noreferrer"
          >
            {link.name}
          </a>
        )
      ))}
    </div>
  );
  const handleNavigation = (path) => {
    Navigate(path);
  };

  return (
    <div>
      <div className="bg-[#000]">
        <div className="flex flex-wrap justify-between max600:flex-col max600:justify-center p-12 gap-6 max1358:justify-center">
          <div className="my-auto ml-8">
            <a href="/" className="transition duration-200 flex justify-center">
              <img className="w-[15rem]" src={logo} alt="Logo" />
            </a>
          </div>

          <div className="grid grid-cols-3 gap-24 max-md:grid-cols-1 text-[#fff]">
            {Object.entries(linkGroups).map(([title, links]) => (
              renderLinkColumn(title, links)
            ))}
          </div>
        </div>

        <div className="h-16 bg-[#FDCF08] flex w-full items-center justify-start px-[1rem]">
          <div className="flex bg-[#000] justify-between items-center w-[8rem] rounded-2xl h-12 p-2">
            <a
              href="https://instagram.com/bworkz_workout?igshid=YmMyMTA2M2Y"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={instagram}
                alt="Instagram"
                className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8 transition-all"
              />
            </a>
            <a
              href="https://www.facebook.com/BworkZLive?mibextid=LQQJ4dY"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebook}
                alt="Facebook"
                className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8 transition-all"
              />
            </a>
            <a
              href="https://youtube.com/@bworkzworkout2004"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={youtube}
                alt="YouTube"
                className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8 transition-all"
              />
            </a>
          </div>
        </div>

        <div className="p-4 flex justify-center text-white gap-2 font-poppins max536:flex-col max536:text-center">
          <div onClick={() => handleNavigation("/privacy")} className="cursor-pointer">
            Privacy Policy
          </div>
          <div className="bg-[#FDCF08] w-1 border-[fff] rounded-md"></div>
          <div onClick={() => handleNavigation("/terms")} className="cursor-pointer">
            Terms and Condition
          </div>
          <div className="bg-[#FDCF08] w-1 border-[fff] rounded-md"></div>
          <div onClick={() => handleNavigation("/refund")} className="cursor-pointer">
            Cancellation/Refund Policy
          </div>
          <div className="bg-[#FDCF08] w-1 border-[fff] rounded-md"></div>
          <div className="text-[1rem]">
            All rights reserved. © {new Date().getFullYear()} bworkzlive.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;