import React from "react";

const WarningModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center" style={{zIndex:1000}}>
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Active Subscription Found
          </h2>
          <p className="text-gray-600 mb-6 text-[1rem]">
            You have already an active subscription. Please cancel that to
            proceed with this plan.
          </p>
          <button
            onClick={onClose}
            className=" w-1/2 text-gray-800 font-semibold px-8 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            style={{backgroundColor:'#FDCF08'}}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
